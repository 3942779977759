{
  "production": false,
  "cookieFirstId": "",
  "russianCookieFirstId": "",
  "googleAnalyticsUACode": "",
  "googleAnalytics4Code": "",
  "googleTagManagementCode": "",
  "googleAdsCode": "",
  "googleSearchConsoleId": "",
  "hotJarCode": "",
  "butterApiUrl": "https://testcontent.fiteq.org",
  "butterCMSQueryParam": "teqball",
  "environmentName": "Test",
  "siteName": "localhost",
  "apiUrl": "https://testapi.fiteq.org",
  "captchaSiteKey": "6LeP7r8aAAAAAG_sOYsJhjqzk7fSsZDx_hAchGRM",
  "teqSiteId": "1",
  "itvfApiUrl": "https://test-api.tablevolleyball.com",
  "storageUrl": "https://assets.fiteq.org",
  "chatbotKey": "",
  "russianDomain": "https://ru-test.teqball.com"
}
